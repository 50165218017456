import React from "react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import Layout from "@/components/Layout";
import Home from "@/components/Home";
import Mobile from "@/components/Mobile";

const Index = () => (
  <Layout hideFunctionality>
    <Mobile />
    {/* <Home /> */}
  </Layout>
);

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  };
}

export default Index;
